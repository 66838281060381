/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */
import {graphql} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {Layout} from '../components'

const BookSite = ({data: {Buch}}) => (
  <Layout customNav>
    <div
      sx={{
        minHeight: '30vh',
        textAlign: 'center',
        p: '0 1.5rem',
        maxWidth: '900px',
        m: [
          'calc(64px + 2rem) auto 6rem auto',
          '',
          'calc(64px + 5rem) auto 11rem auto',
        ],
      }}
    >
      <Styled.h1
        sx={{
          color: (t) => t.colors.green[5],
        }}
      >
        {Buch.data.success_title}
      </Styled.h1>

      <div
        sx={{
          p: {
            fontSize: '1.5rem',
          },
          img: {
            maxWidth: ['300px', '', '30rem'],
          },
        }}
        dangerouslySetInnerHTML={{__html: Buch.data.success_content.html}}
      />
    </div>
  </Layout>
)

export const query = graphql`
  query Successful {
    Buch: prismicBuch {
      data {
        success_title
        success_content {
          html
        }
        book_carousel {
          image {
            url
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BookSite
